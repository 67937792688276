#candidate-name {
  font-size: 3.5rem;
  margin-bottom: 0.5vh;
}

#candidate-role {
  font-size: 2.5rem;
  margin-top: 0.5vh;
}

#title {
  margin-bottom: 5vh;
}