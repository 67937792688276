/* Float Shadow */
#beta-tag {
  margin: 1em;
  position: absolute; 
  top: 0px; 
  right: 50vw; 
  padding: 1em; 
  background-color: orange; 
  color: white;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
#beta-tag:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform, opacity;
  transition-property: transform, opacity;
}
#beta-tag:hover, #beta-tag:focus, #beta-tag:active {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
#beta-tag:hover:before, #beta-tag:focus:before, #beta-tag:active:before {
  opacity: 1;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
}

/* Other Effects */

/* forward skew */
/* #beta-tag {
  position: absolute; 
  bottom: 0px; 
  right: 0px; 
  padding: 1em; 
  background-color: #2098D1; 
  color: white;
  z-index: 2;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
#beta-tag:hover, #beta-tag:focus, #beta-tag:active {
  -webkit-transform: skew(-10deg);
  transform: skew(-10deg);
} */

/* grow shadow */
/* #beta-tag {
  margin: 1em;
  position: absolute; 
  bottom: 0px; 
  right: 0px; 
  padding: 1em; 
  background-color: #2098D1; 
  color: white;
  z-index: 2;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
}
#beta-tag:hover, #beta-tag:focus, #beta-tag:active {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
} */

