@import url(https://fonts.googleapis.com/css?family=Tenor+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Chivo&display=swap);

#candidate-name {
  font-size: 3.5rem;
  margin-bottom: 0.5vh; }

#candidate-role {
  font-size: 2.5rem;
  margin-top: 0.5vh; }

#title {
  margin-bottom: 5vh; }

#summary {
  font-size: 1.2em; }

/* Hover CSS for the CSS effects: https://ianlunn.github.io/Hover */
.experience.role {
  margin-top: 1em;
  margin-bottom: 1vh; }

.experience.company {
  margin-top: 0vh;
  margin-bottom: 1vh; }

/* Underline From Left */
.experience.duty {
  display: block;
  padding: 1.5em;
  border-left: grey 3px solid;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden; }

.experience.duty:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: #2098D1;
  height: 4px;
  transition-property: right;
  transition-duration: 0.65s;
  transition-timing-function: ease-out; }

.experience.duty:hover:before, .experience.duty:focus:before, .experience.duty:active:before {
  right: 0; }

/* Hover CSS for the CSS effects: https://ianlunn.github.io/Hover */
.education.establishment {
  margin-top: 1.5em; }

/* Underline From Left */
.education.course {
  display: block;
  padding: 1.5em;
  border-left: #6ebe23 3px solid;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden; }

.education.course:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: #2098D1;
  height: 4px;
  transition-property: right;
  transition-duration: 0.45s;
  transition-timing-function: ease-out; }

.education.course:hover:before, .education.course:focus:before, .education.course:active:before {
  right: 0; }

/* Hover CSS for the CSS effects: https://ianlunn.github.io/Hover */
/* Underline From Left */
.certificate {
  display: block;
  padding: 1.5em;
  border-left: purple 3px solid;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden; }

.certificate:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: #2098D1;
  height: 4px;
  transition-property: right;
  transition-duration: 0.45s;
  transition-timing-function: ease-out; }

.certificate:hover:before, .certificate:focus:before, .certificate:active:before {
  right: 0; }

/* Hover CSS for the CSS effects: https://ianlunn.github.io/Hover */
/* Underline From Left */
.interest {
  display: block;
  padding: 1.5em;
  border-left: orange 3px solid;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden; }

.interest:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: #2098D1;
  height: 4px;
  transition-property: right;
  transition-duration: 0.45s;
  transition-timing-function: ease-out; }

.interest:hover:before, .interest:focus:before, .interest:active:before {
  right: 0; }

/* Hover CSS for the CSS effects: https://ianlunn.github.io/Hover */
.skill {
  display: block;
  padding: 1em;
  vertical-align: middle;
  border-left: red 10px solid;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  transition-property: color;
  transition-duration: 0.4s; }

.skill:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.4s;
  transition-timing-function: ease-out; }

.focus {
  color: white; }

.focus:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1); }

.education.establishment {
  margin-top: 1.5em; }

/* Underline From Left */
.language {
  display: block;
  padding: 1.5em;
  border-left: yellow 3px solid;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden; }

.language:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: #2098D1;
  height: 4px;
  transition-property: right;
  transition-duration: 0.45s;
  transition-timing-function: ease-out; }

.language:hover:before, .language:focus:before, .language:active:before {
  right: 0; }

/* Float Shadow */
/* .language {
  display: block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.language:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: -webkit-radial-gradient(center, ellipse, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform, opacity;
  transition-property: transform, opacity;
}
.language:hover, .language:focus, .language:active {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
.language:hover:before, .language:focus:before, .language:active:before {
  opacity: 1;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
} */
/* Outline Out */
/* .language {
  display: block;
  padding: 1em;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.language:before {
  content: '';
  position: absolute;
  border: #e1e1e1 solid 4px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: top, right, bottom, left;
  transition-property: top, right, bottom, left;
}
.language:hover:before, .language:focus:before, .language:active:before {
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
} */
/* Hollow */
/* .language {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: background;
  transition-property: background;
  box-shadow: inset 0 0 0 4px #e1e1e1, 0 0 1px rgba(0, 0, 0, 0);
}
.language:hover, .language:focus, .language:active {
  background: none;
} */
/* Trim */
/* .language {
  display: block;
  padding: 2em;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.language:before {
  content: '';
  position: absolute;
  border: white solid 4px;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  opacity: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.language:hover:before, .language:focus:before, .language:active:before {
  opacity: 1;
} */

li {
  margin-top: 2.5vh; }

.section {
  margin-bottom: 3vh; }

/* Float Shadow */
#source-button {
  margin: 1em;
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 1em;
  background-color: #2098D1;
  color: white;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }

#source-button:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  transition-duration: 0.3s;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform; }

#source-button:hover, #source-button:focus, #source-button:active {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px); }

#source-button:hover:before, #source-button:focus:before, #source-button:active:before {
  opacity: 1;
  -webkit-transform: translateY(5px);
  transform: translateY(5px); }

/* Other Effects */
/* forward skew */
/* #source-button {
  position: absolute; 
  bottom: 0px; 
  right: 0px; 
  padding: 1em; 
  background-color: #2098D1; 
  color: white;
  z-index: 2;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
#source-button:hover, #source-button:focus, #source-button:active {
  -webkit-transform: skew(-10deg);
  transform: skew(-10deg);
} */
/* grow shadow */
/* #source-button {
  margin: 1em;
  position: absolute; 
  bottom: 0px; 
  right: 0px; 
  padding: 1em; 
  background-color: #2098D1; 
  color: white;
  z-index: 2;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
}
#source-button:hover, #source-button:focus, #source-button:active {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
} */

/* Float Shadow */
#beta-tag {
  margin: 1em;
  position: absolute; 
  top: 0px; 
  right: 50vw; 
  padding: 1em; 
  background-color: orange; 
  color: white;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
#beta-tag:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: '';
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
  transition-duration: 0.3s;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
}
#beta-tag:hover, #beta-tag:focus, #beta-tag:active {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
#beta-tag:hover:before, #beta-tag:focus:before, #beta-tag:active:before {
  opacity: 1;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
}

/* Other Effects */

/* forward skew */
/* #beta-tag {
  position: absolute; 
  bottom: 0px; 
  right: 0px; 
  padding: 1em; 
  background-color: #2098D1; 
  color: white;
  z-index: 2;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
#beta-tag:hover, #beta-tag:focus, #beta-tag:active {
  -webkit-transform: skew(-10deg);
  transform: skew(-10deg);
} */

/* grow shadow */
/* #beta-tag {
  margin: 1em;
  position: absolute; 
  bottom: 0px; 
  right: 0px; 
  padding: 1em; 
  background-color: #2098D1; 
  color: white;
  z-index: 2;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
}
#beta-tag:hover, #beta-tag:focus, #beta-tag:active {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
} */


.dg li {
  margin-top: 0vh; }

#business-card {
  width: 350px;
  height: 200px;
  text-align: center;
  font-family: 'Tenor Sans';
  color: #9a9a9a;
  transition: color 1.2s;
  margin: auto; }

@media only screen and (max-width: 768px) {
  #business-card {
    margin: 32vh auto; } }

/* Hover CSS for the CSS effects: https://ianlunn.github.io/Hover */
#app-routes {
  position: fixed;
  top: 10vh;
  left: -1vh;
  z-index: 1;
  opacity: 0.7; }

/* Skew Forward */
.app-route.experimental-3d {
  margin-top: 1em;
  padding: 1em 2em;
  background-color: pink;
  display: block;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%; }

.app-route.experimental-3d:hover, .app-route.experimental-3d:focus, .app-route.experimental-3d:active {
  -webkit-transform: skew(-10deg);
  transform: skew(-10deg);
  opacity: 1;
  cursor: pointer; }

/* Skew Backward */
.app-route.basic-page {
  margin-top: 1em;
  padding: 1em 2em;
  background-color: pink;
  display: block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%; }

.app-route.basic-page:hover, .app-route.basic-page:focus, .app-route.basic-page:active {
  -webkit-transform: skew(10deg);
  transform: skew(10deg);
  cursor: pointer; }

/* Skew Forward */
.app-route.landing-page {
  margin-top: 1em;
  padding: 1em 2em;
  background-color: pink;
  display: block;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%; }

.app-route.landing-page:hover, .app-route.landing-page:focus, .app-route.landing-page:active {
  -webkit-transform: skew(-10deg);
  transform: skew(-10deg);
  opacity: 1;
  cursor: pointer; }

