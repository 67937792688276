
/* Hover CSS for the CSS effects: https://ianlunn.github.io/Hover */

$duration: 0.4s;

.skill {
  display: block;
  padding: 1em;
  vertical-align: middle;
  border-left: red 10px solid;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: $duration;
  transition-duration: $duration;
}

.skill:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2098D1;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: $duration;
  transition-duration: $duration;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.focus {
  color: white;
}

.focus:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);

}