@import url('https://fonts.googleapis.com/css?family=Tenor+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chivo&display=swap');

#business-card {
  width: 350px;
  height: 200px;
  text-align: center;
  font-family: 'Tenor Sans';
  color: rgb(154, 154, 154);
  transition: color 1.2s;
  margin: auto;
}

@media only screen and (max-width: 768px) {
  #business-card {
    margin: 32vh auto;
  }
}
